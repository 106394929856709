import React from "react"
import NogrammerStyle from "../components/nogrammer-style"
import Navbar from "../components/navbar"
import NogrammerLayout from "../components/nogrammer-layout"
import HeroSlide from "../components/hero-slide"
import SocialProofSlide from "../components/social-proof-slide"
import HowItWorksSlide from "../components/how-it-works-slide"
import CodeRequestSlide from "../components/code-request-slide"
import MeetTheTeamSlide from "../components/meet-the-team-slide"

export default function Home() {
	return (
		<NogrammerStyle>
			<Navbar />
			<NogrammerLayout>
				<p>Coming soon</p>
			</NogrammerLayout>
		</NogrammerStyle>
	)
}
