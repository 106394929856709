import React from "react"
import { createGlobalStyle } from "styled-components"

import Apercu from "../fonts/apercu/Apercu.ttf"
import ApercuMedium from "../fonts/apercu/Apercu-Medium.otf"
import ApercuBold from "../fonts/apercu/Apercu-Bold.otf"
import ApercuMono from "../fonts/apercu/Apercu-Mono.otf"

const GlobalStyles = createGlobalStyle`
	@font-face {
		font-family: "Apercu";
		src: url(${Apercu});
		font-weight: normal;
		font-style: normal;
	}

	@font-face {
		font-family: "Apercu-Medium";
		src: url(${ApercuMedium});
		font-weight: medium;
		font-style: normal;
	}

	@font-face {
		font-family: "Apercu-Bold";
		src: url(${ApercuBold});
		font-weight: bold;
		font-style: normal;
	}

	@font-face {
		font-family: "Apercu-Mono";
		src: url(${ApercuMono});
		font-weight: normal;
		font-style: normal;
	}

	body {
		margin: 0;
	}

	p {
		margin: 0;
	}
`

export default function NogrammerStyle(props) { return <> <GlobalStyles /> {props.children} </> }