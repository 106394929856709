import React from "react"
import styled from "styled-components"

const Button = styled.button`
	border: none;
	box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
	color: white;
	padding: 1em 2em;
	border-radius: 2px;
	font-family: "Apercu-Bold";
	font-size: 12px;
	text-transform: uppercase;
	background-color: #fc0200;
	text-decoration: none;

	&:active {
		box-shadow: none;
	}

`

export default function NogrammerButton(props) {
	return (
		<Button as="a" href="#code-request">{ props.children }</Button>
	)
}